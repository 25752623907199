export const ErrorCodes = Object.freeze({
  ERR0000: "ERR0000",
  IN0001: "IN0001",
  IN0002: "IN0002",
  IN0003: "IN0003",
  US0001: "US0001",
  US0002: "US0002",
  US0004: "US0004",
  CS0001: "CS0001",
  CS0002: "CS0002",
  CS0003: "CS0003",
  CS0004: "CS0004",
  US0003: "US0003",
  QR0001: "QR0001",
  QR0002: "QR0002",
  QR0003: "QR0003",
  QR0004: "QR0004",
  QR0005: "QR0005",
  CH0001: "CH0001",
  CH0002: "CH0002",
  PC0001: "PC0001",
  PC0002: "PC0002",
  PC0003: "PC0003",
  QC0001: "QC0001",

  SI0001: "SI0001",
  SI0002: "SI0002",
  SI0003: "SI0003",
  SI0004: "SI0004",
  SI0005: "SI0005",
  SI0006: "SI0006",
  SI0007: "SI0007",
  SI0008: "SI0008",
  SI0009: "SI0009",
  SI0010: "SI0010",
  S30001: "S30001",
  SI0011: "SI0011",

  SI0012: "SI0012",
  SI0013: "SI0013",

  SI0014: "SI0014",
  SI0015: "SI0015",

  AI0001: "AI0001",
  AI0002: "AI0002",
  AI0003: "AI0003",
  AI0004: "AI0004",
  AI0005: "AI0005",
  AI0006: "AI0006",
  AI0007: "AI0007",
  AI0008: "AI0008",
  QS0001: "QS0001",
  CB0001: "CB0001",

  IN0004: "IN0004",
  SP0001: "SP0001",
});
